import React, { useContext } from 'react';
import {
  Box,
  Tabs,
  Tab,
} from '@material-ui/core/';

import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import './FloormapTabs.scss';

const FloormapTabs = () => {
  const { tab, setTab } = useContext(FloormapContext);

  const tabData = [
    {
      name: 'distribucion',
      label: 'DISTRIBUCIÓN DE ZONAS',
      tabId: 1,
    },
    {
      name: 'gestion',
      label: 'GESTIÓN DE RESERVACIONES',
      tabId: 2,
    },
    {
      name: 'diagrama',
      label: 'HISTORIAL DE RESERVAS',
      tabId: 3,
    },
  ];

  const handleChange = (event, newValue) => {
    localStorage.setItem('FloormapCurrentTab', newValue);
    setTab(newValue);
  };

  return (
    <Box
      component="div"
      className="floormap__tabs-box  dFlex flex-column w100"
    >
      <Box
        component="div"
        className="w100"
      >
        <Tabs
          className="w100"
          variant="fullWidth"
          scrollButtons="off"
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {
            tabData.map((tabInfo) => (
              <Tab
                value={tabInfo.tabId}
                key={tabInfo.name}
                label={(
                  <div className="">
                    <span className="font__commutter">
                      {tabInfo.label}
                    </span>
                  </div>
                )}
              />
            ))
          }
        </Tabs>
      </Box>
    </Box>
  );
};
export default FloormapTabs;
