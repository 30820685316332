/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import FloormapDistribucionContext from '../FloormapDistribucion/FloormapDistribucionContext/FloormapDistribucionContext';
import { Switch } from 'antd';

import './FloormapItemMenu.scss';

import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography
} from '@material-ui/core';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { array } from 'prop-types';

const FloormapItemMenu = () => {

  const { arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode, numeroMesa, setNumeroMesa } = useContext(FloormapDistribucionContext);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const esVisible = (bandera) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    imageNode.attrs.visibilidad = bandera;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.visibilidad = bandera;
      }
      return image;
    }));

  }

  const esDraggable = (bandera) => {

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    imageNode.attrs.draggable = bandera;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.draggable = bandera;
      }
      return image;
    }));

  }

  const estaBloqueada = (bandera) => {

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    imageNode.attrs.blocked = bandera;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.blocked = bandera;
      }
      return image;
    }));

  }

  const cambiarTipo = (value) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    imageNode.attrs.type = value;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.type = value;
      }
      return image;
    }));
  }

  const cambiarTipoSilla = (value) => {
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    imageNode.attrs.tipoSilla = value;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.tipoSilla = value;
      }
      return image;
    }));
  }

  const cambiarNumeroMesa = (value) => {

    const isNumeric = n => !isNaN(n);
    
    if(isNumeric(value)){

      if(value >= 1 && value != imageNode.attrs.numMesa){
        //Guardar estado
        setArrayDeshacer(arrayDeshacer.concat([
          JSON.parse(JSON.stringify(images))
        ]));
        setArrayRehacer([]);

        const id = imageNode.attrs.idNum;
        imageNode.attrs.numMesa = value;

        setImages(images.map((image) => {
          if(id == image.idNum){
            image.numMesa = value;
          }
          return image;
        }));
      }
    }
  }

  const cambiarMinimoMesa = (value) => {

    const isNumeric = n => !isNaN(n);

    if(isNumeric(value)){

      let personaMax = 0;
      let personaMin = 0;

      // Limitar personas maximas y minimas
      if(imageNode.attrs.type == 1 || imageNode.attrs.type == 2){
        personaMax = 2;
        personaMin = 1;
      }else if(imageNode.attrs.type == 3 || imageNode.attrs.type == 4 || imageNode.attrs.type == 5 || imageNode.attrs.type == 6){
        personaMax = 4;
        personaMin = 1;
      }else if(imageNode.attrs.type == 7){
        personaMax = 5;
        personaMin = 1;
      }else if(imageNode.attrs.type == 8 || imageNode.attrs.type == 10 || imageNode.attrs.type == 12){
        personaMax = 6;
        personaMin = 1;
      }else if(imageNode.attrs.type == 9 || imageNode.attrs.type == 11 || imageNode.attrs.type == 13){
        personaMax = 8;
        personaMin = 1;
      }


      if(value >= personaMin && value <= personaMax && value <= imageNode.attrs.personaMaximas){
        //Guardar estado
        setArrayDeshacer(arrayDeshacer.concat([
          JSON.parse(JSON.stringify(images))
        ]));
        setArrayRehacer([]);

        const id = imageNode.attrs.idNum;
        imageNode.attrs.personaMinimas = value;

        setImages(images.map((image) => {
          if(id == image.idNum){
            image.personaMinimas = value;
          }
          return image;
        }));
      }
    }
  }


  const cambiarMaximoMesa = (value) => {

    const isNumeric = n => !isNaN(n);

    if(isNumeric(value)){

      let personaMax = 0;
      let personaMin = 0;

      // Limitar personas maximas y minimas
      if(imageNode.attrs.type == 1 || imageNode.attrs.type == 2){
        personaMax = 2;
        personaMin = 1;
      }else if(imageNode.attrs.type == 3 || imageNode.attrs.type == 4 || imageNode.attrs.type == 5 || imageNode.attrs.type == 6){
        personaMax = 4;
        personaMin = 1;
      }else if(imageNode.attrs.type == 7){
        personaMax = 5;
        personaMin = 1;
      }else if(imageNode.attrs.type == 8 || imageNode.attrs.type == 10 || imageNode.attrs.type == 12){
        personaMax = 6;
        personaMin = 1;
      }else if(imageNode.attrs.type == 9 || imageNode.attrs.type == 11 || imageNode.attrs.type == 13){
        personaMax = 8;
        personaMin = 1;
      }


      if(value >= personaMin && value <= personaMax && value >= imageNode.attrs.personaMinimas){
        //Guardar estado
        setArrayDeshacer(arrayDeshacer.concat([
          JSON.parse(JSON.stringify(images))
        ]));
        setArrayRehacer([]);

        const id = imageNode.attrs.idNum;
        imageNode.attrs.personaMaximas = value;

        setImages(images.map((image) => {
          if(id == image.idNum){
            image.personaMaximas = value;
          }
          return image;
        }));
      }
    }
  }

  const borrarElemento = () => {
    console.log(imageNode.attrs.categoria);
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = imageNode.attrs.idNum;
    setImages(images.filter((image) => image.idNum != id));
    setImageNode(null);
    setSelectedItem(false);
  }
  
  const duplicarElemento = () => {
    console.log(imageNode.attrs);
    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    if(imageNode.attrs.categoria == "mesa"){
      setImages(images.concat(
        [
          {
            x: imageNode.attrs.x + 10,
            y: imageNode.attrs.y + 10,
            src: imageNode.attrs.image,
            fill: (imageNode.attrs.categoria == "forma" || imageNode.attrs.categoria == "texto") ? imageNode.attrs.fill : null,
            stroke: imageNode.attrs.categoria == "forma" ? imageNode.attrs.stroke : null,
            strokeWidth: imageNode.attrs.categoria == "forma" ? imageNode.attrs.strokeWidth : null,
            fontSize: imageNode.attrs.categoria == "texto" ? imageNode.attrs.fontSize : null,
            text: imageNode.attrs.categoria == "texto" ? imageNode.attrs.text : null,
            category: imageNode.attrs.categoria,
            type: imageNode.attrs.type,
            idNum: id,
            numMesa: numeroMesa,
            draggable: imageNode.attrs.draggable,
            visibilidad: imageNode.attrs.visibilidad,
            tipoSilla: imageNode.attrs.tipoSilla,
            blocked: imageNode.attrs.blocked,
            personaMinimas: imageNode.attrs.personaMinimas,
            personaMaximas: imageNode.attrs.personaMaximas,
            ancho: imageNode.attrs.width,
            largo: imageNode.attrs.height,
          }
        ]
      ));
      setNumeroMesa(numeroMesa + 1);
    }else{
      setImages(images.concat(
        [
          {
            x: imageNode.attrs.x + 10,
            y: imageNode.attrs.y + 10,
            src: (imageNode.attrs.categoria == "forma" || imageNode.attrs.categoria == "texto") ? null : imageNode.attrs.image.currentSrc ,
            fill: (imageNode.attrs.categoria == "forma" || imageNode.attrs.categoria == "texto") ? imageNode.attrs.fill : null,
            stroke: imageNode.attrs.categoria == "forma" ? imageNode.attrs.stroke : null,
            strokeWidth: imageNode.attrs.categoria == "forma" ? imageNode.attrs.strokeWidth : null,
            fontSize: imageNode.attrs.categoria == "texto" ? imageNode.attrs.fontSize : null,
            text: imageNode.attrs.categoria == "texto" ? imageNode.attrs.text : null,
            category: imageNode.attrs.categoria,
            type: imageNode.attrs.type,
            idNum: id,
            ancho: imageNode.attrs.width,
            largo: imageNode.attrs.height,
          }
        ]
      ));
    }
    setId(id + 1);
    console.log(images);
    setSelectedItem(false);
  }

  const propiedadesMesa = () => {
    
    return(
      <div className='border-bottom pt-1 pb-1'>
        <div className='ml-1 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Numero de mesa</Typography>
          <TextField
            className='floormap-item-input'
            type="number"
            onChange={(e) => {
              
              cambiarNumeroMesa(e.target.value);
              
            }}
            inputProps={{min: 1, max: 99, defaultValue: imageNode.attrs.numMesa, className: "floormap-item-input__value"}}
          />
        </div>
        <div className='mt-05 ml-1 dFlex flex-column  justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Tipo de mesa</Typography>
          <div className='dFlex flex-column align-center justify-between mt-05'>
            <TextField
              select
              defaultValue={imageNode.attrs.type}
              onChange={(e) => {
                
                cambiarTipo(e.target.value);
                
              }}
              SelectProps={{
                IconComponent:ExpandMoreIcon,
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  },
                  getContentAnchorEl: null
                }
              }}
              
              className='border-select w110'
            >
              <MenuItem key={1} value={1}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Regular con dos sillas</Typography>
              </MenuItem>
              <MenuItem key={2} value={2}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Circular con dos sillas</Typography>
              </MenuItem>
              <MenuItem key={3} value={3}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Mediana con cuatro sillas</Typography>
              </MenuItem>
              <MenuItem key={4} value={4}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Regular con cuatro sillas</Typography>
              </MenuItem>
              <MenuItem key={5} value={5}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Circular con cuatro sillas</Typography>
              </MenuItem>
              <MenuItem key={6} value={6}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Ovalada con cuatro sillas</Typography>
              </MenuItem>
              <MenuItem key={7} value={7}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Circular con cinco sillas</Typography>
              </MenuItem>
              <MenuItem key={8} value={8}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Mediana con seis sillas</Typography>
              </MenuItem>
              <MenuItem key={9} value={9}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Circular grande con ocho sillas</Typography>
              </MenuItem>
              <MenuItem key={10} value={10}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Gigante con seis sillas</Typography>
              </MenuItem>
              <MenuItem key={11} value={11}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Gigante con ocho sillas</Typography>
              </MenuItem>
              <MenuItem key={12} value={12}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Grande con seis sillas</Typography>
              </MenuItem>
              <MenuItem key={13} value={13}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Grande con ocho sillas</Typography>
              </MenuItem>
            </TextField>
          </div>
        </div>
        <div className='mt-05 ml-1 dFlex flex-column  justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Tipo de sillas</Typography>
          <div className='dFlex flex-column align-center justify-between mt-05'>
            <TextField
              select
              defaultValue={imageNode.attrs.tipoSilla}
              onChange={(e) => {
                
                cambiarTipoSilla(e.target.value);
                
              }}
              SelectProps={{
                IconComponent:ExpandMoreIcon,
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  },
                  getContentAnchorEl: null
                }
              }}
              
              className='border-select w110'
            >
              <MenuItem key={"Sillas altas de bar"} value={"Sillas altas de bar"}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Sillas altas de bar</Typography>
              </MenuItem>
              <MenuItem key={"Sillas bajas de bar"} value={"Sillas bajas de bar"}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Sillas bajas de bar</Typography>
              </MenuItem>
              <MenuItem key={"Sillas elegantes"} value={"Sillas elegantes"}>
                <Typography className='tiny-font pading-item-select' variant='body2'>Sillas elegantes</Typography>
              </MenuItem>
            </TextField>
          </div>
        </div>
        <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Personas minimas</Typography>
          <TextField
            className='floormap-item-input'
            type="number"
            onChange={(e) => {
              
              cambiarMinimoMesa(e.target.value);
              
            }}
            inputProps={{min: 1, max: 99, defaultValue: imageNode.attrs.personaMinimas, className: "floormap-item-input__value"}}
          />
        </div>
        <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Personas maximas</Typography>
          <TextField
            className='floormap-item-input'
            type="number"
            onChange={(e) => {
              
              cambiarMaximoMesa(e.target.value);
              
            }}
            inputProps={{min: 1, max: 99, defaultValue: imageNode.attrs.personaMaximas, className: "floormap-item-input__value"}}
          />
        </div>
        <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>¿Visible al público?</Typography>
          <FormControlLabel labelPlacement="start" control={<Switch className="switcher" color="primary" onChange={(value) => {esVisible(value)}} checked={imageNode.attrs.visibilidad} />} />
        </div>
        <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Se puede mover?</Typography>
          <FormControlLabel labelPlacement="start" control={<Switch className="switcher" color="primary" onChange={(value) => {esDraggable(value)}} checked={imageNode.attrs.draggable} />} />
        </div>
        <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
          <Typography variant='body2' className='bold-font'>Bloquear mesa</Typography>
          <FormControlLabel labelPlacement="start" control={<Switch className="switcher" color="primary" onChange={(value) => {estaBloqueada(value)}} checked={imageNode.attrs.blocked} />} />
        </div>
      </div>
    )
  }

  return(
    <>
      <div className='border-menu-accordion mb-2'>
          <div className='border-bottom pt-1 pb-1'>
            <div className='ml-1'>
              <Typography className='bold-font'>Tipo de {capitalizeFirstLetter(imageNode.attrs.categoria)}</Typography>
            </div>
          </div>
          {imageNode.attrs.categoria == "mesa" && propiedadesMesa()}
          <div className='border-bottom pt-1 pb-1 w100 dFlex flex-column align-center justify-center'>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="w90 mb-1e"
              onClick={duplicarElemento}
            
            >
              Duplicar
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="w90"
              onClick={borrarElemento}
            
            >
              Borrar
            </Button>
          </div>
        </div>
    </>
  )
}

export default FloormapItemMenu;

